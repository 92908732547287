import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import Layout from "../components/Layout";

import "../assets/css/App.css";
import "../assets/css/index.css";

export default () => (
  <Layout>
    <SEO
      title="Commercial Laundry Appliances"
      description="Washing machine or laundry applicance out of action? We can help with breakdowns and repairs, fit new parts and get your machines back to working like new"
      keywords="commercial laundry services london, commercial laundry services potters bar, commercial laundry services central london, commercial laundry services repair, commercial laundry services installation"
    />
    <Jumbotron imgClassName="img10" />
    <div class="white-segment text-left">
      <h1 class="heading-color">Laundry Appliances</h1>
      <p>
        BBIS Commercial heating Ltd can help if you are looking for commercial
        laundry services. We understand that having your commercial laundry
        appliances out of action is going to affect your business hugely, so
        having the best professional service is critical. We can help with
        breakdowns and repairs supplying and fitting spare parts if needed.
      </p>
      <p>Get in touch today to arrange a call out </p>
      <p>
        Call us on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
    </div>
  </Layout>
);
